<template>

  <div v-if="items" class="banner-container">

    <div v-if="items.resourceUri" class="banner-image">
      <img v-if="items && items.resourceUri && items.resourceUri !== null" class="banner-image-src"
        :src="$rt(items.resourceUri)" />
    </div>
    <video v-if="items.videoUri" class="banner-video" playsinline autoplay muted loop poster>
      <source class="banner-video" :src="$rt(items.videoUri)" />
    </video>
    <div class="banner-content" :class="getMediaType">
      <div class="banner-content-holder">
        <LocLink :to="items.bannerLinkPath ? $rt(items.bannerLinkPath) : ''" class="banner-link"
          v-if="items.bannerLink">{{ $rt(items.bannerLink).toUpperCase() }}</LocLink>
        <div class="banner-title">{{ $rt(items.title) }}</div>
        <p class="banner-text" v-if="items.paragraphs" id="banner-text" v-for="(text, index) in items.paragraphs">
          {{ $rt(text) }}
        </p>
        <BaseButton v-if="items.isButton" :label="$rt(items.buttonText)" :buttonStyle="bannerStyle"
          :url="$rt(items.buttonPath)" class="banner-btn" />
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from './BaseButton.vue';
import LocLink from './LocLink.vue';


export default {
  components: {
    BaseButton, LocLink
  },
  data() {
    return {
      bannerStyle: {
        color: '#0089D0',
        hover: '#212121',
      },
    };
  },
  props: ['items'],
  computed: {
    getMediaType() {
      return this.items.videoUri ? "" : "img-banner-content";
    }
  },
};
</script>

<style scoped>
.banner-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: max-content;
}

.banner-content-holder {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

.banner-link {
  text-decoration: underline;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}

.banner-text {
  max-width: 500px;
  font-size: 20px;
  line-height: 1.4;
}

.banner-title {
  font-weight: 900;
  font-size: 60px;
  max-width: 600px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.banner-content {
  position: absolute;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 600px;
  padding-left: 40px;
}

/* this class is dynamically added as needed - do not delete */
.img-banner-content {
  height: 400px;
}

.banner-image {
  width: 100%;
  height: 400px;
  overflow: hidden;
  background-color: lightgray;
}

.banner-video {
  grid-column: 1/2;
  grid-row: 1/2;
  width: 100%;
  height: 600px;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-image-src {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner-btn {
  margin-top: 20px;
}


@media only screen and (max-width: 1000px) {
  /*.banner-container {
    height: max-content;
  }*/

  .banner-link {
    font-size: 15px;
  }

  .banner-title {
    font-size: 40px;
  }

  .banner-text {
    font-size: 16px !important;
  }
}




@media only screen and (max-width: 800px) {
  .banner-image {
    height: 250px;
  }

  .img-banner-content {
    height: 250px;
  }
}





@media only screen and (max-width: 500px) {

  .banner-link {
    font-size: 14px;
  }

  .banner-title {
    font-size: 30px;
  }

  .banner-text {
    font-size: 15px !important;
  }

  .banner-image {
    height: 200px;
  }

  .img-banner-content {
    height: 200px !important;
  }

}
</style>